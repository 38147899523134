input[type="text"],
input[type="email"],
input[type="number"],
select{
    width: 100%;
    padding: 12px 20px;
    margin: 8px;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}
input[type="submit"]{
    width: 100%;
    background-color: #4caf50;
    color: white;
    margin: 8px;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}
input[type="submit"]:hover{
    background-color: #45a049;
}