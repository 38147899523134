.card{
    width: 30%;
    display: flex;
    flex-direction: column;
    border: 1px solid red;
    align-content: center;
    margin: 0 auto;
}
.card-header{
    height: 30%;
    background-color: #f63997;
    color: white;
    text-align: center;
}
.card-header p{
    font-size: 20px;
}
.container{
    padding: 4px 16px;
}