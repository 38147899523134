.header{
    width: 100%;
    overflow: hidden;
    background-color: #000000;
}

.header p{
    float: left;
    color: white;
    text-align: center;
    padding: 10px;
    text-decoration: none;
    font-size: 18px;
    line-height: 15px;
    border-radius: 4px;
}
.header p.logo{
    font-size: 25px;
    font-weight: bold;
    color: #f63997;
}
.header  p.active{
    background-color: #f63997;
    color: #fff;
}
.header-right{
    float: right;
}
.inputfield{
    width: 40% !important;
    padding: 1px;
    margin-top: 5px;
    margin-right: 6px;
    border: none;
    font-size: 17px;
}

@media screen and (max-width: 500px) {
    .header p{
        float: none;
        display: block;
        text-align: left;
    }
    .header-right{
        float: none;
    }
}