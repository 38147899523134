.table-stayled{
    border-collapse: collapse;
    margin: auto;
    font-size: 0.9em;
    font-family: sans-serif;
    /* max-width: 1000px; */
    /* width: 900px; */
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  }
  .table-stayled thead tr {
    background-color: #f63997;
    color: #fff;
    text-align: left;
  }
  .table-stayled th,
  .table-stayled td {
    padding: 12px 15px;
  }
  
  .table-stayled tbody tr {
    border-bottom: 1px solid #dddddd;
  }
  .table-stayled tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
  }
  .table-stayled tbody tr:hover {
    background-color: coral;
    color: white;
  }
  .table-stayled tbody tr:last-of-type {
    border-bottom: 1px solid #f63997;
  }
  .btn {
    border: none;
    color: white;
    padding: 5px 8px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border-radius: 5px;
    font-size: 1rem;
    margin: 4px 2px;
    cursor: pointer;
  }
  .btn-edit {
    background-color: #008cba;
  }
  .btn-delete {
    background-color: #f44336;
  }
  .btn-view {
    background-color: #e7e7e7;
    color: black;
  }
  .dropdown {
    width: 20%;
    margin: 5px;
  }
  .btn-reset {
    background-color: crimson;
    color: white;
  }
  .btn-active{
    background-color: green;
    color: white;
  }
  .btn-inactive{
    background-color: gray;
    color: white;
  }
  